
import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible/flexible.js'
import router from './router/index'
import VueRouter from 'vue-router'
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(VueRouter);

import 'vant/lib/index.css';
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { Uploader } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { DatetimePicker } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Toast } from 'vant';
import { Cascader } from 'vant';
import { Area } from 'vant';
import { Picker } from 'vant';
import { Dialog } from 'vant';
import { Overlay } from 'vant';
import { Loading } from 'vant';
// 全局注册
Vue.use(Dialog);
Vue.use(Picker);
Vue.use(Area);
Vue.use(Cascader);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Overlay);
Vue.use(Loading);

new Vue({
  router,
  render: h => h(App),
  beforeCreate(){
    //安装全局事件总线，$bus就是当前应用的vm
    Vue.prototype.$bus=this
  },
}).$mount('#app')
