import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [{
    path: '/',
    component:  ()=> import("@/view/collect-information"),
},    {
    path: '/back',
    component: ()=> import("@/view/back"),
    redirect: "/back/userMsg",
    children:[
        {
            path: 'userMsg',
            component: ()=> import("@/view/userMsg"),
        },
        {
            path: 'world',
            component: ()=> import("@/view/world"),
        },
    ]
},
    {
        path: '/login',
        component: ()=> import("@/view/Login"),
    },
    {
        path: '/inquire',
        component: ()=> import("@/view/inquire"),
    },
]

const router = new Router({
    mode: 'history',
    routes: constantRouterMap
})

export default router

